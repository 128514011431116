import React, { useState, useCallback, useEffect } from "react"
import { Layout, TextField, Card, FormLayout } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

const AdminSettings = props => {
  const { firebase, shop } = props

  const [email, setEmail] = useState(null)
  const [updating, setUpdating] = useState(false)

  const handleEmailChange = useCallback(value => {
    setEmail(value)
  }, [])

  const handleSaveChange = () => {
    setUpdating(true)
    firebase
      .firestore()
      .doc(`shops/${shop}/settings/admin`)
      .set({ email }, { merge: true })
      .then(() => {
        setUpdating(false)
      })
  }

  useEffect(() => {
    const getGlobalSettings = firebase
      .firestore()
      .doc(`shops/${shop}/settings/admin`)
      .onSnapshot(snapshot => {
        const data = snapshot.data()
        if (data) {
          setEmail(data.email || false)
        }
      })

    return () => getGlobalSettings
  }, [firebase, shop])

  return (
    <>
      <Layout.AnnotatedSection title="Admin Settings" description="Set basic admin infomation.">
        <Card
          sectioned
          primaryFooterAction={{
            content: "Save",
            onAction: handleSaveChange,
            loading: updating,
          }}
        >
          <FormLayout>
            <TextField type="email" label="Admin Email Address" value={email} onChange={handleEmailChange} />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </>
  )
}

export default ReactifyApp.Firebase.withFirebase(AdminSettings)
